import { ICustomerPortalTheme, IModernThemeSettings } from "@smartrr/shared/entities/CustomerPortalTheme";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { isEqual } from "lodash";

export type TrendingSectionThemeValues = IModernThemeSettings["sections"]["trending"];
interface TrendingListStore {
  hasThemeChanges: boolean;
  theme: ICustomerPortalTheme | null;
  initialTrendingSectionThemeValues: TrendingSectionThemeValues | null;
  trendingSectionThemeValues: TrendingSectionThemeValues | null;
  errors: {
    renderToast: boolean;
    toastMessage: string;
  };

  actions: {
    theme: {
      discard(): void;
      get(): Promise<void>;
      initializeTrendingSectionThemeValues(): void;
      resetError(): void;
      setError(errorMessage: string): void;
      update(themeId: number, updatedThemeProperties: ICustomerPortalTheme): Promise<void>;
      updateHasThemeChanges(hasChanges: boolean): void;
      updateTrendingSectionThemeValues(newValues: TrendingSectionThemeValues): void;
    };
  };
}

export const useTrendingListStore = create<TrendingListStore>()(
  immer((set, get) => ({
    hasThemeChanges: false,
    theme: null,
    initialTrendingSectionThemeValues: null,
    trendingSectionThemeValues: null,
    errors: {
      renderToast: false,
      toastMessage: "",
    },

    actions: {
      theme: {
        discard() {
          const { initialTrendingSectionThemeValues } = get();
          set(draft => {
            draft.trendingSectionThemeValues = initialTrendingSectionThemeValues;
            draft.hasThemeChanges = false;
          });
        },

        async get() {
          const response = await typedFrontendVendorApi.getReq("/customer-portal-theme");

          if (response.type === "error") {
            this.setError("Error retrieving customer portal theme");
            return;
          }

          if (response.type !== "success") {
            this.setError("Error retrieving customer portal theme");
            return;
          }

          if (response.type === "success") {
            const actions = get().actions.theme;
            set(draft => {
              draft.theme = response.body;
              draft.hasThemeChanges = false;
            });
            actions.resetError();
            get().actions.theme.initializeTrendingSectionThemeValues();
          }
        },

        async update(themeId, updatedThemeProperties) {
          const response = await typedFrontendVendorApi.postReq("/customer-portal-theme", {
            reqBody: {
              themeShopifyId: themeId,
              updatedThemeProperties,
            },
          });

          if (response.type === "error") {
            this.setError("Error sending customer portal theme updates");
            return;
          }

          if (response.type !== "success") {
            this.setError("Error sending customer portal theme updates");
            return;
          }

          if (response.type === "success") {
            set(draft => {
              draft.theme = updatedThemeProperties;
            });
            this.resetError();
          }
        },

        updateHasThemeChanges(hasChanges) {
          set(draft => {
            draft.hasThemeChanges = hasChanges;
          });
        },

        initializeTrendingSectionThemeValues() {
          const theme = get().theme;
          if (theme) {
            const trending: TrendingSectionThemeValues | undefined =
              theme.modernThemeSettings?.sections?.trending;
            if (trending) {
              const { display, heading, caption, showHeart, imgUrl, sortMethod } = trending;
              set(draft => {
                draft.initialTrendingSectionThemeValues = {
                  display,
                  heading,
                  caption,
                  showHeart,
                  imgUrl,
                  sortMethod,
                };
                draft.trendingSectionThemeValues = { display, heading, caption, showHeart, imgUrl, sortMethod };
              });
            }
          }
        },

        resetError() {
          set(draft => {
            draft.errors.renderToast = false;
            draft.errors.toastMessage = "";
          });
        },

        setError(errorMessage: string) {
          set(draft => {
            draft.errors.renderToast = true;
            draft.errors.toastMessage = errorMessage;
          });
        },

        updateTrendingSectionThemeValues(newValues) {
          const initialTrendingSectionThemeValues = get().initialTrendingSectionThemeValues;
          set(draft => {
            draft.trendingSectionThemeValues = newValues;
          });

          if (isEqual(initialTrendingSectionThemeValues, newValues)) {
            set(draft => {
              draft.hasThemeChanges = false;
            });
          } else {
            set(draft => {
              draft.hasThemeChanges = true;
            });
          }
        },
      },
    },
  }))
);

const initialStoreState = useTrendingListStore.getState();

export const TrendingListStoreAccess = {
  useActions() {
    return useTrendingListStore(state => state.actions);
  },

  useErrors() {
    return useTrendingListStore(state => state.errors);
  },

  useHasThemeChanges() {
    return useTrendingListStore(state => state.hasThemeChanges);
  },

  useTheme() {
    return useTrendingListStore(state => state.theme);
  },

  useInitialTrendingSectionThemeValues() {
    return useTrendingListStore(state => state.initialTrendingSectionThemeValues);
  },

  useTrendingSectionThemeValues() {
    return useTrendingListStore(state => state.trendingSectionThemeValues);
  },

  testing: {
    store() {
      return useTrendingListStore.getState;
    },

    actions() {
      return useTrendingListStore.getState().actions;
    },

    reset() {
      useTrendingListStore.setState(initialStoreState);
    },
  },
};
