import { Box, Stack as MUIStack, Switch } from "@mui/material";
import {
  Button,
  ContextualSaveBar,
  Icon,
  Label,
  LegacyCard,
  LegacyStack,
  Link,
  Pagination,
  ResourceList,
  Scrollable,
  Select,
  Spinner,
  Text,
  TextField,
} from "@shopify/polaris";
import { HideMinor, SearchMinor, ViewMinor } from "@shopify/polaris-icons";
import { ISellingPlanFromZodWithId } from "@smartrr/shared/entities/SellingPlanGroup";
import { TrendingHeart } from "@smartrr/shared/icons/Aquarius/TrendingHeart";
import { filterNil } from "@smartrr/shared/utils/filterUndefined";
import { isValidImageUrl } from "@smartrr/shared/utils/isValidImageUrl";
import { MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";
import { ValidSortMethods, sortProductsListByMethod } from "@smartrr/shared/utils/sortBy";
import {
  useVariantShopifyIDToPurchasableMap,
  useVariantToPurchasableMap,
} from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { cloneDeep, flatten, isEmpty, isEqual, set } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { BrowseProductsModal } from "@vendor-app/app/_sharedComponents/BrowseProductsModal";
import { AddProductMajorSVG } from "@vendor-app/app/_sharedComponents/SVGs/AddProductMajorSVG";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { PaginationContainer } from "../../../AdminSubscriptionDetailsRoute/libs";
import { ILinkedVariantWithPurchaseableName, productSortingOptions } from "../../constants";
import { LinkWrapper, NoProductsContainer, TrendingListsDataProps } from "../../libs";
import { PricingPolicies } from "../PricingPolicies";
import {
  SellingPlanGroupProductsListItem,
  SellingPlanGroupVariantToPurchaseableListItem,
} from "../SellingPlanGroupProductsListItem";
import { PRODUCT_SELECTION_MAX, hasSelectedIdsReachedMaxQuantity } from "./utils";

import { TrendingListStoreAccess } from "./store";

export const TrendingListsData = ({
  sellingPlanGroup: initialSellingPlanGroup,
  mainThemeId,
  onUpdateTrendingList,
  trendingSectionThemeValues,
}: TrendingListsDataProps): JSX.Element => {
  const actions = TrendingListStoreAccess.useActions();
  const theme = TrendingListStoreAccess.useTheme();
  const { toastMessage, renderToast } = TrendingListStoreAccess.useErrors();
  const hasThemeChanges = TrendingListStoreAccess.useHasThemeChanges();
  const { updateTrendingSectionThemeValues } = actions.theme;

  const { purchasables, isLoading: areProductsLoading } = useSmartrrVendorSelector(state => state.purchasables);
  const { addToast } = useToast();
  const variantToPurchasableMap = useVariantToPurchasableMap(purchasables);
  const variantShopifyIdToPurchaseableMap = useVariantShopifyIDToPurchasableMap(purchasables);

  const [hasChanges, setHasChanges] = useState(false);
  const [sortValue, setSortValue] = useState<ValidSortMethods>(
    trendingSectionThemeValues.sortMethod || ValidSortMethods.MANUAL
  );

  const [sellingPlan, setSellingPlan] = useState<ISellingPlanFromZodWithId>(
    initialSellingPlanGroup.sellingPlans[0]
  );

  const [isSaving, setIsSaving] = useState(false);

  const [productIds, setProductIds] = useState(initialSellingPlanGroup.productIds);
  const [variantIds, setVariantIds] = useState<string[]>(initialSellingPlanGroup.productVariantIds);

  const [showProductOptionsModal, setShowProductOptionsModal] = useState(false);
  const [modalSelectedProductOrVariantIds, setModalSelectedProductOrVariantIds] = useState<string[]>([]);

  const [filterProductsText, setFilterProductsText] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(0);

  const listHeading = useMemo(() => trendingSectionThemeValues.heading, [trendingSectionThemeValues.heading]);
  const listCaption = useMemo(() => trendingSectionThemeValues.caption, [trendingSectionThemeValues.caption]);
  const showHeart = useMemo(() => trendingSectionThemeValues.showHeart, [trendingSectionThemeValues.showHeart]);
  const imgUrl = useMemo(() => trendingSectionThemeValues.imgUrl, [trendingSectionThemeValues.imgUrl]);

  const [urlValidationError, setUrlValidationError] = useState<boolean>(false);

  const applyValuesToTheming = useCallback(async () => {
    const updatedThemeProperties = {
      modernThemeSettings: {
        ...theme?.modernThemeSettings!,
        sections: {
          ...theme?.modernThemeSettings?.sections!,
          trending: {
            display: trendingSectionThemeValues.display!,
            caption: listCaption!,
            heading: listHeading!,
            showHeart: showHeart!,
            imgUrl: imgUrl!,
            sortMethod: sortValue!,
          },
        },
      },
    };

    await actions.theme.update(mainThemeId, updatedThemeProperties);
    await actions.theme.get();
  }, [imgUrl, listCaption, listHeading, mainThemeId, showHeart, sortValue, theme, trendingSectionThemeValues]);

  const onSave = useCallback(async () => {
    setIsSaving(true);
    setUrlValidationError(false);

    if (hasThemeChanges) {
      if (!isEqual(trendingSectionThemeValues.imgUrl, imgUrl)) {
        const isImageValid = await isValidImageUrl(imgUrl!);
        if (!isImageValid) {
          setUrlValidationError(true);
          addToast("Please enter a valid image url", true);
          setIsSaving(false);
          return;
        }
      }

      await applyValuesToTheming();
    }

    if (hasChanges) {
      await onUpdateTrendingList(sellingPlan, productIds, variantIds);
    }

    setIsSaving(false);
  }, [
    addToast,
    applyValuesToTheming,
    imgUrl,
    productIds,
    variantIds,
    sellingPlan,
    hasThemeChanges,
    hasChanges,
    trendingSectionThemeValues.imgUrl,
  ]);

  const onDiscard = useCallback(() => {
    setSellingPlan(cloneDeep(initialSellingPlanGroup.sellingPlans[0]));
    if (trendingSectionThemeValues) {
      actions.theme.discard();
    }
  }, [initialSellingPlanGroup.sellingPlans, trendingSectionThemeValues]);

  const onEditProductsAndVariantsClick = useCallback(async () => {
    setShowProductOptionsModal(true);
    setModalSelectedProductOrVariantIds([...variantIds, ...productIds]);
  }, [productIds, variantIds]);

  const onEditProductsOrVariantsCb = useCallback(async () => {
    setProductIds(modalSelectedProductOrVariantIds.filter(p => p.includes("Product/")));
    setVariantIds(modalSelectedProductOrVariantIds.filter(p => p.includes("ProductVariant")));

    setShowProductOptionsModal(false);
  }, [modalSelectedProductOrVariantIds]);

  useEffect(() => {
    setHasChanges(!isEqual(initialSellingPlanGroup.sellingPlans[0].pricingPolicies, sellingPlan.pricingPolicies));
  }, [sellingPlan.pricingPolicies, initialSellingPlanGroup.sellingPlans[0].pricingPolicies]);

  useEffect(() => {
    setHasChanges(
      !isEqual(productIds, initialSellingPlanGroup.productIds) ||
        !isEqual(variantIds, initialSellingPlanGroup.productVariantIds)
    );
  }, [productIds, variantIds, initialSellingPlanGroup.productIds, initialSellingPlanGroup.productVariantIds]);

  const onUpdateSellingPlan = (sellingPlan: ISellingPlanFromZodWithId, properties: { [key: string]: any }) => {
    let newSellingPlan = cloneDeep(sellingPlan);
    for (const [path, newValue] of Object.entries(properties)) {
      newSellingPlan = set(newSellingPlan, path, newValue);
    }

    setSellingPlan(newSellingPlan);
  };

  const handleRemoveProductsAndVariants = async (vntIds: string[], productId?: string) => {
    setModalSelectedProductOrVariantIds([
      ...productIds.filter(id => id !== productId),
      ...variantIds.filter(id => !vntIds.includes(id)),
    ]);

    setProductIds(productIds.filter(id => id !== productId));
    setVariantIds(variantIds.filter(id => !vntIds.includes(id)));
  };

  const linkedProducts = filterNil(
    productIds.map(productId => purchasables.find(purch => purch.shopifyId === productId))
  );

  const linkedVariantsToPurchasable: ILinkedVariantWithPurchaseableName[] = filterNil(
    flatten(
      purchasables.reduce<any[]>((arr, purch) => {
        if (purch.shopifyId && !productIds.includes(purch.shopifyId) && purch.vnts?.length) {
          arr.push(
            purch.vnts?.map(vnt => ({
              ...vnt,
              purchasableName: purch.purchasableName,
            }))
          );
        }
        return arr;
      }, [])
    ).filter(purch => purch?.shopifyId && variantIds.includes(purch.shopifyId))
  );

  const reachedMaxQuantity = (addedItem: string): boolean => {
    const reachedMax = hasSelectedIdsReachedMaxQuantity(
      addedItem,
      modalSelectedProductOrVariantIds,
      variantShopifyIdToPurchaseableMap
    );
    if (reachedMax) {
      addToast("Maximum selected products reached");
      return true;
    }
    return false;
  };

  const purchasableOptions = useMemo(() => {
    const filteredAndSortedPurchasables = purchasables.filter(p => !!p.shopifyId && !p.isDraftOrArchived).sort();

    if (filterProductsText) {
      return filteredAndSortedPurchasables.filter(p =>
        p.purchasableName.toLocaleLowerCase().includes(filterProductsText.toLocaleLowerCase())
      );
    }

    return filteredAndSortedPurchasables;
  }, [purchasables, filterProductsText]);

  const totalPages = Math.ceil(purchasableOptions.length / MAX_PAGE_SIZE);

  useEffect(() => {
    if (renderToast) {
      addToast(toastMessage, true);
    }
  }, [renderToast]);

  if (!trendingSectionThemeValues) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      {hasChanges || hasThemeChanges ? (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{ onAction: onSave, loading: isSaving, content: "Save changes", disabled: false }}
          discardAction={{ content: "Discard changes", onAction: onDiscard }}
        />
      ) : null}
      <React.Fragment>
        <LegacyCard>
          <LegacyCard.Section>
            <MUIStack direction="row" justifyContent="space-between">
              <Text variant="headingMd" as="h2">
                {isEmpty(listHeading) ? "Trending list" : listHeading}
              </Text>

              <MUIStack
                direction="row"
                spacing="5px"
                alignItems="center"
                sx={{
                  svg: {
                    fill: "var(--p-text-subdued)",
                  },
                }}
              >
                {trendingSectionThemeValues.display ? (
                  <LinkWrapper>
                    <Link url="/admin/configure/theming" external removeUnderline>
                      <Icon source={ViewMinor} />
                      <p style={{ color: "var(--p-interactive)", marginLeft: "7px" }}>
                        Visible in Account Portal
                      </p>
                    </Link>
                  </LinkWrapper>
                ) : (
                  <React.Fragment>
                    <Icon source={HideMinor} />
                    <p style={{ color: "var(--p-text-subdued)" }}>Hidden in Account Portal</p>
                  </React.Fragment>
                )}
              </MUIStack>
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <MUIStack spacing="30px">
              <TextField
                label="Heading"
                value={listHeading ?? ""}
                placeholder="Example: Ava Price's Black Friday Faves"
                onChange={e => updateTrendingSectionThemeValues({ ...trendingSectionThemeValues, heading: e })}
                autoComplete="off"
                max={50}
              />
              <TextField
                label="Caption text"
                value={listCaption ?? ""}
                onChange={e => updateTrendingSectionThemeValues({ ...trendingSectionThemeValues, caption: e })}
                placeholder='Example: "My current makeup go-tos. Day time application tip: Apply one pump on my hands, warm it up by rubbing my hands together and apply directly on my face!"'
                multiline={4}
                max={250}
                autoComplete="off"
              />
              <MUIStack justifyContent="space-between" direction="row">
                <MUIStack direction="column">
                  <div>
                    <p style={{ fontSize: "14px", lineHeight: "20px" }}>Image</p>
                    <Text variant="bodySm" as="p">
                      Recommended size: 300x300px .jpeg, .png, or .svg
                    </Text>
                  </div>
                  <div style={{ marginTop: "3px" }}>
                    <TextField
                      label="Media url"
                      value={imgUrl ?? ""}
                      onChange={e => {
                        updateTrendingSectionThemeValues({ ...trendingSectionThemeValues, imgUrl: e });
                        setUrlValidationError(false);
                      }}
                      placeholder={"https://www.website.com/image.jpg"}
                      error={urlValidationError ? "Invalid image url" : ""}
                      autoComplete="off"
                    />
                  </div>
                  <MUIStack
                    spacing="7px"
                    direction="row"
                    sx={{
                      marginTop: "10px",
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  >
                    <TrendingHeart />
                    <p style={{ fontSize: "14px" }}>Show heart?</p>
                    <Switch
                      value={false}
                      size="small"
                      onChange={() =>
                        updateTrendingSectionThemeValues({
                          ...trendingSectionThemeValues,
                          showHeart: !trendingSectionThemeValues.showHeart,
                        })
                      }
                      defaultChecked={showHeart ?? false}
                    />
                  </MUIStack>
                </MUIStack>
                <MUIStack textAlign="end">
                  <p className="Polaris-Text--subdued" style={{ fontSize: "14px" }}>
                    Preview
                  </p>
                  <Box
                    sx={{
                      display: "block !important",
                      background: `url(${imgUrl})`,
                      height: "150px",
                      width: "150px",
                      backgroundColor: "#E4E4E4",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      borderRadius: "137px",
                      position: "relative",
                      "& svg": {
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                      },
                    }}
                  >
                    {!!showHeart && <TrendingHeart />}
                  </Box>
                </MUIStack>
              </MUIStack>
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <MUIStack
              direction="column"
              justifyContent="space-between"
              sx={{
                ".Polaris-FormLayout__Item": {
                  width: "285px",
                  "& > *": {
                    width: "100%",
                  },
                },
              }}
            >
              <Label id={""}>List discount</Label>
              <PricingPolicies
                sellingPlan={sellingPlan}
                fixedPolicyOnly={true}
                onPoliciesUpdate={pricingPolicies => onUpdateSellingPlan(sellingPlan, { pricingPolicies })}
              />
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <MUIStack
              justifyContent="space-between"
              alignItems="end"
              direction="row"
              sx={{
                ".Polaris-Button": {
                  marginLeft: "4px",
                },
                ".Polaris-Select": {
                  width: "210px",
                  marginLeft: "10px",
                },
              }}
            >
              <TextField
                autoComplete="off"
                label={`List products (max ${PRODUCT_SELECTION_MAX})`}
                placeholder="Search products"
                prefix={<Icon source={SearchMinor} color="base" />}
                value={searchText}
                onChange={setSearchText}
              />
              <Button onClick={onEditProductsAndVariantsClick}>Browse</Button>
              <Select
                label="Sort:"
                options={productSortingOptions}
                onChange={selected => {
                  setSortValue(
                    Object.values(ValidSortMethods)[
                      Object.values(ValidSortMethods).indexOf(selected as ValidSortMethods) as number
                    ]
                  );
                }}
                value={sortValue}
                labelInline
              />
            </MUIStack>
          </LegacyCard.Section>
          <LegacyCard.Section>
            <Scrollable
              vertical
              hint
              shadow
              style={{
                maxHeight: "350px",
                position: "relative",
              }}
            >
              {areProductsLoading ? (
                <LegacyStack distribution="center" alignment="center">
                  <Spinner />
                </LegacyStack>
              ) : (
                <React.Fragment>
                  {linkedProducts.length || linkedVariantsToPurchasable.length ? (
                    <React.Fragment>
                      <ResourceList
                        items={sortProductsListByMethod(
                          linkedProducts.filter(p =>
                            p.purchasableName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                          ),
                          sortValue
                        )}
                        renderItem={purchasable => (
                          <SellingPlanGroupProductsListItem
                            purchasable={purchasable}
                            variantIds={variantIds}
                            variantToPurchasableMap={variantToPurchasableMap}
                            handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                          />
                        )}
                      />
                      <ResourceList
                        items={linkedVariantsToPurchasable.filter(p =>
                          p.purchasableName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                        )}
                        renderItem={(variant, _, index) => (
                          <SellingPlanGroupVariantToPurchaseableListItem
                            index={index}
                            variant={variant}
                            variantToPurchasableMap={variantToPurchasableMap}
                            linkedProducts={linkedProducts}
                            handleRemoveProductsAndVariants={handleRemoveProductsAndVariants}
                          />
                        )}
                      />
                    </React.Fragment>
                  ) : (
                    <NoProductsContainer>
                      <LegacyStack vertical spacing="baseTight" alignment="center" distribution="center">
                        <AddProductMajorSVG />
                        <Text variant="headingLg" as="p">
                          There are no products yet
                        </Text>
                        <Text variant="bodyMd" as="p" color="subdued">
                          Browse to add products and variants
                        </Text>
                      </LegacyStack>
                    </NoProductsContainer>
                  )}
                </React.Fragment>
              )}
            </Scrollable>
          </LegacyCard.Section>
        </LegacyCard>
      </React.Fragment>
      <BrowseProductsModal
        purchasableOptions={purchasableOptions.slice(
          pageNumber * MAX_PAGE_SIZE,
          pageNumber * MAX_PAGE_SIZE + MAX_PAGE_SIZE
        )}
        selectedProductsAndVariants={modalSelectedProductOrVariantIds}
        searchText={filterProductsText}
        title={
          <LegacyStack vertical spacing="extraTight">
            <Text variant="headingLg" as="p">
              Browse products
            </Text>
            <p style={{ fontSize: "14px", lineHeight: "20px" }}>
              Select at most {PRODUCT_SELECTION_MAX} products and their corresponding variants to be featured on a
              trending products list
            </p>
          </LegacyStack>
        }
        open={showProductOptionsModal}
        onClose={() => {
          setShowProductOptionsModal(false);
          setFilterProductsText("");
        }}
        primaryAction={{
          content: "Confirm",
          onAction: onEditProductsOrVariantsCb,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction() {
              setShowProductOptionsModal(false);
              setFilterProductsText("");
            },
          },
        ]}
        footer={
          <PaginationContainer>
            <Pagination
              hasNext={totalPages > pageNumber + 1}
              hasPrevious={pageNumber != 0}
              label={`Showing ${pageNumber + 1} of ${totalPages || 1}`}
              onNext={() => setPageNumber(prev => prev + 1)}
              onPrevious={() => setPageNumber(prev => prev - 1)}
            />
          </PaginationContainer>
        }
        onSearchChange={text => {
          setFilterProductsText(text);
          //reset page on filter change to avoid blank card
          setPageNumber(0);
        }}
        setSelectedProductAndVariantIds={setModalSelectedProductOrVariantIds}
        reachedMaxQuantity={reachedMaxQuantity}
      />
    </React.Fragment>
  );
};
